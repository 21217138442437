const config = {
  app: {
    url: process.env.REACT_APP_WEBSITE_URL,
    title: process.env.REACT_APP_PAGE_TITLE,
  },
  api: {
    url: process.env.REACT_APP_API_URL,
  }

}

export default config;