export const RUN_BOOK_SEARCH = 'book-search/books/START_BOOK_RUN_BOOK_SEARCHSEARCH';
export const RUN_BOOK_SEARCH_SUCCESS = 'book-search/books/RUN_BOOK_SEARCH_SUCCESS';
export const RUN_BOOK_SEARCH_FAIL = 'book-search/books/RUN_BOOK_SEARCH_FAIL';
export const RUN_BOOK_SEARCH_TIMEOUT = 'book-search/books/RUN_BOOK_SEARCH_TIMEOUT';

export const FETCH_BOOKS_REQUEST = 'book-search/books/FETCH_BOOKS_REQUEST';
export const FETCH_BOOKS_SUCCESS = 'book-search/books/FETCH_BOOKS_SUCCESS';
export const FETCH_BOOKS_FAIL = 'book-search/books/FETCH_BOOKS_FAIL';

export const FETCH_BOOKS_STOCKS_REQUEST = 'book-search/books/FETCH_BOOKS_STOCKS_REQUEST';
export const FETCH_BOOKS_STOCKS_SUCCESS = 'book-search/books/FETCH_BOOKS_STOCKS_SUCCESS';
export const FETCH_BOOKS_STOCKS_FAIL = 'book-search/books/FETCH_BOOKS_STOCKS_FAIL';
export const CANCEL_FETCHING_BOOKS_STOCKS = 'book-search.books/CANCEL_FETCHING_BOOKS_STOCKS';
export const CANCEL_FETCHING_BOOKS_STOCKS_DONE = 'book-search.books/CANCEL_FETCHING_BOOKS_STOCKS_DONE';